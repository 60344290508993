import { defineStore } from 'pinia'
import CryptoJS from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';

export const useRoomStore = defineStore(
'roomInfos',{
  state: () => ({
    uuid: undefined,
    icon_id: undefined,
    nickname: "",
    room: undefined,
    owner: undefined,
    isRoom: true,
    enters:0,
    battle_info: new Battle(),
    auth: new Auth(),
    game: undefined,
    prepare: undefined,
    users_cache: {},
    seconds: 0,
    timer: undefined,
    spectators: undefined,
    userListLisener: undefined,
    }),
  getters: {
    battle_status() {
      if(this.battle_info.status == BattleStatus.forcestop||this.battle_info.status ==BattleStatus.userstop){
        return this.battle_info.status;
      }
      if(this.battle_info.evaluators.length == 1 
        && this.battle_info.opponents.length == 2){
        return this.battle_info.status;
        }
        else{
          return BattleStatus.matching;
        }
    },
  },
  actions: {
    setIconID(id) {
      this.icon_id = id;
    },
    setNickname(name) {
      this.nickname = name;
    },
    getUUID() {
      if(!this.uuid){
        this.uuid = uuidv4();
      }
      return this.uuid;
    },
    getIconID() {
      if(!this.icon_id||this.icon_id<1||this.icon_id>19){
        this.icon_id = idStringToNumer(this.uuid)%19+1;
      }
      return this.icon_id;
    },
    getNickname() {
      return this.nickname;
    },
    setBattleTime(sec) {
      this.seconds = sec;
      if(this.battle_info.evaluators.length == 1 
        && this.battle_info.opponents.length == 2
        && (this.battle_info.status == BattleStatus.battling 
          || (this.battle_info.status == BattleStatus.readying)
          ||this.battle_info.status == BattleStatus.evaluating
          ||this.battle_info.status == BattleStatus.finished)){
          if(this.timer){
            clearInterval(this.timer);
            this.timer = undefined;
          }
          const this_ = this;
          this.timer = setInterval(() => {
            this_.seconds--;
            if(this_.seconds <= 0){
              clearInterval(this_.timer);
              this_.timer = undefined;
            }else if(this_.battle_info.status == BattleStatus.battling&&
              this_.battle_info.result
              &&this_.battle_info.result[this_.auth.user.id]
              &&this_.battle_info.result[this_.auth.user.id].message
              ){
                // console.log("post ready");
                // clearInterval(this_.timer);
                // this_.timer = undefined;
            }
          }, 1000);
        }
        else{
          if(this.timer){
            clearInterval(this.timer);
            this.timer = undefined;
          }
        }
    },
    setNextStatus(status) {
      this.battle_info.status = status;
    },
    setAuth(auth) {
      this.auth.isAuthenticated = auth.isAuthenticated;
      this.auth.token = auth.token;
      this.auth.user=auth.user;
    },
    setOpponents(users) {
      this.battle_info.opponents = users;
      if (this.battle_info.evaluators.length == 1 
        && this.battle_info.opponents.length == 2) {
        this.battle_info.status = BattleStatus.odaiing;
      }
    },
    setEvaluators(users) {
      this.battle_info.evaluators = users;
      if(this.battle_info.evaluators.length == 1 
        && this.battle_info.opponents.length == 2){
        this.battle_info.status = BattleStatus.odaiing;
      }
    },
    setResetBattle(){
      const match_ch=this.battle_info.match_chan;
      this.battle_info = new Battle();
      this.battle_info.match_chan = match_ch;
    },
    setResetAll(){
      this.auth = new Auth();
      this.setBattleFinish();
      this.battle_info = new Battle();
    },
    setPreBattle(){
      const match_ch=this.battle_info.match_chan?this.battle_info.match_chan:this.auth.user.id;
      this.prepare = usePreBattle(match_ch,(doc)=>{
        console.log("setPreBattle=>",doc);
        if (!doc) {
          navigateTo("/");
          return;
        }
        this.room = doc.channel;
        useGetBattleID(doc.channel,(roomdoc)=>{
          this.battle_info.battle_chan = roomdoc.battle;
          this.owner = roomdoc.owner;
          if(this.battle_info.battle_chan&&this.battle_info.battle_chan.length > 0){
            this.setBattleStart();
          }else{
            this.setResetBattle()
          }
        });
        // this.battle_info.battle_chan = doc.channel;
        if(doc.in_battle == 0){
          navigateTo("/");
        }
        this.auth.user.role = doc.role;
      });
    },
    setBattleStart(){
      if(this.game){
        this.game();
        this.game = undefined;
      }
      if(this.userListLisener){
        this.userListLisener();
        this.userListLisener = undefined;
      }
      this.userListLisener = useSnapListUser(this.room,(users)=>{
          this.spectators = users;
      })
      this.game = useGameStart(this.battle_info.battle_chan,(doc)=>{
          if(!doc){
            return;
          }
          // if(doc.isRoom&&doc.isRoom == 1){
          //   console.log("setBattleStart=>",doc);
          //   if(doc.enters){
          //     this.enters = doc.enters;
          //     if(doc.enters<3){
          //       return;
          //     }
          //   }
          // }
          if (doc.ready){
            this.battle_info.ready = Object.keys(doc.ready);
          }else{
            this.battle_info.ready = [];
          }
          if(doc.opponents&&doc.opponents.length>0){
            this.battle_info.opponents = doc.opponents.map((id) => {
              if(this.spectators&&this.spectators.length>0){
                const user = this.spectators.find((user)=>user.id==id);
                if(user){
                  const ruser = new User();
                  ruser.id = id;
                  ruser.icon = mapToIconWith(user.icon);
                  ruser.name = user.name?user.name:characters[idStringToNumer(id)%19+1];
                  ruser.starCount = user.starCount?user.starCount:0;
                  return ruser;
                }
              }
              const ruser = new User();
              ruser.id = id;
              ruser.icon = mapToIcon(id);
              ruser.name = characters[idStringToNumer(id)%19+1];
              ruser.starCount = 0;
              return ruser;
            });
        }else{
          this.battle_info.opponents = [];
        }

        if(doc.evaluators&&doc.evaluators.length>0){
          this.battle_info.evaluators = doc.evaluators.map((id) => {
            if(this.spectators&&this.spectators.length>0){
              const user = this.spectators.find((user)=>user.id==id);
              if(user){
                const ruser = new User();
                ruser.id = id;
                ruser.icon = mapToIconWith(user.icon);
                ruser.name = user.name?user.name:characters[idStringToNumer(id)%19+1];
                ruser.starCount = user.starCount?user.starCount:0;
                return ruser;
              }
            }
            const ruser = new User();
            ruser.id = id;
            ruser.icon = mapToIcon(id);
            ruser.name = characters[idStringToNumer(id)%19+1];
            ruser.starCount = 0;
            return ruser;
          });
        }else{
          this.battle_info.evaluators = [];
        }

          this.battle_info.decrypted = false;
          
          this.battle_info.status = doc.status;
          this.battle_info.victory = doc.victory;
          if(!this.battle_info.odai||(this.battle_info.odai&&this.battle_info.odai.id != doc.odai&&doc.odai>0)){
            const odai = new Odai();
            odai.id = doc.odai;
            odai.owner = doc.odai_owner;
            odai.url = doc.odai_url;
            this.battle_info.odai = odai;
          }else{
            if(doc.odai==0&&!this.battle_info.odai){
              const odai = new Odai()
              this.battle_info.odai = odai;
            }
          }

          this.battle_info.result = doc.result;

          // //復号化 ボケ内容
          if(!this.battle_info.decrypted&&doc.key&&doc.key.length > 0){
            this.battle_info.key = doc.key;
            // console.log(this.battle_info.key);
            function decrypt(content, key) {
              let hash_key =  CryptoJS.SHA256(key);
              var decrypted = CryptoJS.AES.decrypt(content, hash_key, {
                mode: CryptoJS.mode.CBC,
                iv:CryptoJS.enc.Utf8.parse("oCnZEo6E4wLayRZp"),
                padding: CryptoJS.pad.Pkcs7
              });
              return decrypted.toString(CryptoJS.enc.Utf8);
            }
            this.battle_info.result[this.battle_info.opponents[0].id].message = decrypt(doc.result[this.battle_info.opponents[0].id].message,doc.key);
            this.battle_info.result[this.battle_info.opponents[1].id].message = decrypt(doc.result[this.battle_info.opponents[1].id].message,doc.key);
            
            this.battle_info.decrypted = true;
          }
        }
      );
    },
    setBattleFinish(){
      console.log("setBattleFinish");
      if(this.game){
        this.game();
        this.game = undefined;
      }
      if(this.userListLisener){
        this.userListLisener()
        this.userListLisener = undefined;
      }
      if(this.prepare){
        this.prepare();
        this.prepare = undefined;
      }
      this.battle_info = new Battle();
      this.users_cache={};
      this.seconds = TimeForBokete.TimeForStop;
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = undefined;
      }
    },
  },
  persist: {
    storage: persistedState.localStorage,
    enabled: true,
    // strategies: [
    //   {storage: persistedState.localStorage, paths: ['battle_info', 'auth', 'game', 'prepare', 'users_cache','seconds']}
    // ]
  },
})
