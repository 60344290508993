import { default as indexuW1mAOOnVYMeta } from "/Users/kawa/Desktop/battle/boke-battle/pages/index.vue?macro=true";
import { default as battleoITvBbJjxxMeta } from "/Users/kawa/Desktop/battle/boke-battle/pages/room/battle.vue?macro=true";
import { default as index8nlIkYi6OrMeta } from "/Users/kawa/Desktop/battle/boke-battle/pages/room/index.vue?macro=true";
import { default as battleZavIKB3tjsMeta } from "/Users/kawa/Desktop/battle/boke-battle/pages/world/battle.vue?macro=true";
import { default as forgetZ5mSHtiBJTMeta } from "/Users/kawa/Desktop/battle/boke-battle/pages/world/forget.vue?macro=true";
import { default as indexI1YjxpN27tMeta } from "/Users/kawa/Desktop/battle/boke-battle/pages/world/index.vue?macro=true";
import { default as loginxwLTv40qUWMeta } from "/Users/kawa/Desktop/battle/boke-battle/pages/world/login.vue?macro=true";
import { default as ranking9Q0lq5hwPkMeta } from "/Users/kawa/Desktop/battle/boke-battle/pages/world/ranking.vue?macro=true";
import { default as registerQcZwEiJTMwMeta } from "/Users/kawa/Desktop/battle/boke-battle/pages/world/register.vue?macro=true";
export default [
  {
    name: indexuW1mAOOnVYMeta?.name ?? "index",
    path: indexuW1mAOOnVYMeta?.path ?? "/",
    meta: indexuW1mAOOnVYMeta || {},
    alias: indexuW1mAOOnVYMeta?.alias || [],
    redirect: indexuW1mAOOnVYMeta?.redirect || undefined,
    component: () => import("/Users/kawa/Desktop/battle/boke-battle/pages/index.vue").then(m => m.default || m)
  },
  {
    name: battleoITvBbJjxxMeta?.name ?? "room-battle",
    path: battleoITvBbJjxxMeta?.path ?? "/room/battle",
    meta: battleoITvBbJjxxMeta || {},
    alias: battleoITvBbJjxxMeta?.alias || [],
    redirect: battleoITvBbJjxxMeta?.redirect || undefined,
    component: () => import("/Users/kawa/Desktop/battle/boke-battle/pages/room/battle.vue").then(m => m.default || m)
  },
  {
    name: index8nlIkYi6OrMeta?.name ?? "room",
    path: index8nlIkYi6OrMeta?.path ?? "/room",
    meta: index8nlIkYi6OrMeta || {},
    alias: index8nlIkYi6OrMeta?.alias || [],
    redirect: index8nlIkYi6OrMeta?.redirect || undefined,
    component: () => import("/Users/kawa/Desktop/battle/boke-battle/pages/room/index.vue").then(m => m.default || m)
  },
  {
    name: battleZavIKB3tjsMeta?.name ?? "world-battle",
    path: battleZavIKB3tjsMeta?.path ?? "/world/battle",
    meta: battleZavIKB3tjsMeta || {},
    alias: battleZavIKB3tjsMeta?.alias || [],
    redirect: battleZavIKB3tjsMeta?.redirect || undefined,
    component: () => import("/Users/kawa/Desktop/battle/boke-battle/pages/world/battle.vue").then(m => m.default || m)
  },
  {
    name: forgetZ5mSHtiBJTMeta?.name ?? "world-forget",
    path: forgetZ5mSHtiBJTMeta?.path ?? "/world/forget",
    meta: forgetZ5mSHtiBJTMeta || {},
    alias: forgetZ5mSHtiBJTMeta?.alias || [],
    redirect: forgetZ5mSHtiBJTMeta?.redirect || undefined,
    component: () => import("/Users/kawa/Desktop/battle/boke-battle/pages/world/forget.vue").then(m => m.default || m)
  },
  {
    name: indexI1YjxpN27tMeta?.name ?? "world",
    path: indexI1YjxpN27tMeta?.path ?? "/world",
    meta: indexI1YjxpN27tMeta || {},
    alias: indexI1YjxpN27tMeta?.alias || [],
    redirect: indexI1YjxpN27tMeta?.redirect || undefined,
    component: () => import("/Users/kawa/Desktop/battle/boke-battle/pages/world/index.vue").then(m => m.default || m)
  },
  {
    name: loginxwLTv40qUWMeta?.name ?? "world-login",
    path: loginxwLTv40qUWMeta?.path ?? "/world/login",
    meta: loginxwLTv40qUWMeta || {},
    alias: loginxwLTv40qUWMeta?.alias || [],
    redirect: loginxwLTv40qUWMeta?.redirect || undefined,
    component: () => import("/Users/kawa/Desktop/battle/boke-battle/pages/world/login.vue").then(m => m.default || m)
  },
  {
    name: ranking9Q0lq5hwPkMeta?.name ?? "world-ranking",
    path: ranking9Q0lq5hwPkMeta?.path ?? "/world/ranking",
    meta: ranking9Q0lq5hwPkMeta || {},
    alias: ranking9Q0lq5hwPkMeta?.alias || [],
    redirect: ranking9Q0lq5hwPkMeta?.redirect || undefined,
    component: () => import("/Users/kawa/Desktop/battle/boke-battle/pages/world/ranking.vue").then(m => m.default || m)
  },
  {
    name: registerQcZwEiJTMwMeta?.name ?? "world-register",
    path: registerQcZwEiJTMwMeta?.path ?? "/world/register",
    meta: registerQcZwEiJTMwMeta || {},
    alias: registerQcZwEiJTMwMeta?.alias || [],
    redirect: registerQcZwEiJTMwMeta?.redirect || undefined,
    component: () => import("/Users/kawa/Desktop/battle/boke-battle/pages/world/register.vue").then(m => m.default || m)
  }
]