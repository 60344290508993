import Pica from 'pica';
export function secondsToHMS(seconds) {
    const hours = Math.floor(seconds / 3600);
    seconds %= 3600;                                    
    const minutes = Math.floor(seconds / 60);           
    const sec = seconds % 60;                           

    const paddedHours = String(hours).padStart(2, '0');
    const paddedMinutes = String(minutes).padStart(2, '0');
    const paddedSeconds = String(sec).padStart(2, '0');
    if (hours === 0) {
        return `${paddedMinutes}:${paddedSeconds}`;
    }
    return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
}

export function resizeAndCropCenter(srcImage, targetWidth, targetHeight, callback) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    
    canvas.width = targetWidth;
    canvas.height = targetHeight;

    const aspectRatio = srcImage.width / srcImage.height;
    let scaledWidth, scaledHeight;

    if (srcImage.width > srcImage.height) {
        scaledWidth = targetWidth * aspectRatio;
        scaledHeight = targetHeight;
    } else {
        scaledWidth = targetWidth;
        scaledHeight = targetHeight / aspectRatio;
    }

    const tmpCanvas = document.createElement('canvas');
    tmpCanvas.width = scaledWidth;
    tmpCanvas.height = scaledHeight;
    const pica = new Pica();
    pica.resize(srcImage, tmpCanvas)
        .then(result => {
            // Crop the center
            ctx.drawImage(
                result, 
                (scaledWidth - targetWidth) / 2,
                (scaledHeight - targetHeight) / 2,
                targetWidth,
                targetHeight,
                0,
                0,
                targetWidth,
                targetHeight
            );
            callback(canvas);
        })
        .catch(err => {
            console.error('Error resizing and cropping image:', err);
        });
}

export function canvasToBlob(canvas, callback, mimeType='image/jpeg', quality=1.0) {
    canvas.toBlob((blob) => {
        callback(blob);
    }, mimeType, quality);
}

export function idStringToNumer(idString) {
    // 0-9 -> 0-9
    // a-z -> 10-35
    // A-Z -> 36-61
    // _ -> 62
    // - -> 63
    // Others -> 64
    //idString is sentence ,return total number
    let total = 0;
    for (let i = 0; i < idString.length; i++) {
        const charCode = idString.charCodeAt(i);
        if (charCode >= 48 && charCode <= 57) {
            total = total + charCode - 48;
        } else if (charCode >= 97 && charCode <= 122) {
            total = total + charCode - 87;
        } else if (charCode >= 65 && charCode <= 90) {
            total = total + charCode - 29;
        } else if (charCode == 95) {
            total = total + 62;
        } else if (charCode == 45) {
            total = total + 63;
        } else {
            total = total + 64;
        }
    }
    return total;
}

export function mapToIcon(idString) {
    return `${location.origin}/img/characters/${idStringToNumer(idString)%19+1}.gif`;
}

export function mapToIconWith(number) {
    return `${location.origin}/img/characters/${number}.gif`;
}