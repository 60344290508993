export default defineNuxtRouteMiddleware((to, from) => {
    if(to.path !== '/world/login'&& 
    to.path !== '/world/forget' && 
    to.path !== '/world/register'&&
    to.path !== '/'&&
    to.path.indexOf("/room")<0) {
        const battle = useBattleStore();
        const token = useCookie('token');
        if (battle.auth.isAuthenticated === false||
            !token.value ||
             token.value !== battle.auth.token) {
                battle.setResetAll();
            return navigateTo('/world/login')
        }
    }else{
        if(to.path.indexOf("/room/battle")>=0){
            const battle = useRoomStore();
            if (!battle.uuid) {
                return navigateTo('/')
            }
        }else if ( to.path === '/' && from.path === '/'){
            const common = useCommonStore();
            common.ani = false;
        }
    }  

})