import { collection, addDoc, setDoc, doc, getFirestore, updateDoc, arrayUnion, arrayRemove, DocumentReference, increment } from "firebase/firestore"; 
export async function useGameEnter(role,callback) {
  // console.log('useGameEnter');
  const battle = useBattleStore();
  const config = useRuntimeConfig();
  const { data, pending, error, refresh } = await useFetch("/battle",
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + battle.auth.token
    }, 
    body: JSON.stringify({
      enter: true,
      role: role}),
    baseURL: `${config.public.apiBaseUrl}`,
    onResponse({ request, response, options }) {
      callback(true,response._data)
    },
    onResponseError({ request, response, options }) {
      callback(true,undefined)
    },
    onRequestError({ request, options, error }) {
      callback(true,undefined)
    }
  }
  );
}

export async function useGameExit(role,callback) {
  const battle = useBattleStore();
  const config = useRuntimeConfig();
  const { data, pending, error, refresh } = await useFetch("/battle",
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + battle.auth.token
    }, 
    body: JSON.stringify({
      enter: false,
      role: role}),
    baseURL: `${config.public.apiBaseUrl}`,
    onResponse({ request, response, options }) {
      callback(true,response._data)
    },
    onResponseError({ request, response, options }) {
      callback(true,undefined)
    },
    onRequestError({ request, options, error }) {
      callback(true,undefined)
    }
  }
  );
}

export async function useForceExit(battle) {
  // const battle = useBattleStore();
  const config = useRuntimeConfig();
  const { data, pending, error, refresh } = await useFetch("/battle/exit",
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + battle.auth.token
    }, 
    body: JSON.stringify({}),
    baseURL: `${config.public.apiBaseUrl}`,
    onResponse({ request, response, options }) {
      callback(true,response._data)
    },
    onResponseError({ request, response, options }) {
      callback(true,undefined)
    },
    onRequestError({ request, options, error }) {
      callback(true,undefined)
    }
  }
  );
}

export async function useGameReady(battle) {
  return new Promise(async (resolve, reject) => {
  // const battle = useBattleStore();
  console.log('useGameReady');
  console.log(battle.auth.token);
  const config = useRuntimeConfig();
  const { data, pending, error, refresh } = await useFetch("/battle/ready",
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + battle.auth.token
    }, 
    body: JSON.stringify({}),
    baseURL: `${config.public.apiBaseUrl}`,
    onResponse({ request, response, options }) {
      if (response && response._data) {
        // console.log(response._data);
        resolve(response._data);
      } else {
        reject(new Error("Response data not found"));
      }
    }
  });
  if (error) {
    reject(error);
  }
  });
}

export async function useGameCancelReady(battle) {
  return new Promise(async (resolve, reject) => {
  // const battle = useBattleStore();
  console.log('useGameCancelReady');
  console.log(battle.auth.token);
  const config = useRuntimeConfig();
  const { data, pending, error, refresh } = await useFetch("/battle/ready",
  {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + battle.auth.token
    }, 
    body: JSON.stringify({}),
    baseURL: `${config.public.apiBaseUrl}`,
    onResponse({ request, response, options }) {
      if (response && response._data) {
        resolve(response._data);
      } else {
        reject(new Error("Response data not found"));
      }
    }
  });
  if (error) {
    reject(error);
  }
  });
}


export async function usePostBoke(battle, message, role) {
  return new Promise(async (resolve, reject) => {
    const config = useRuntimeConfig();
    if(role !== Role.opponents){
      reject(new Error("is not opponents"));
    }
    const { data, pending, error, refresh } = await useFetch("/battle/boke",
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + battle.auth.token
      }, 
      body: JSON.stringify({
        message: message,
        battle_id: battle.battle_info.battle_chan}),
      baseURL: `${config.public.apiBaseUrl}`,
      onResponse({ request, response, options }) {
        if (response && response._data) {
          // console.log(response._data);
          resolve(response._data);
        } else {
          reject(new Error("Response data not found"));
        }
      }
    });
    if (error) {
        reject(error);
    }
  });
}


export async function useSelectBoke(battle,select_user_id, role) {
  // const battle = useBattleStore();
  return new Promise(async (resolve, reject) => {
    const config = useRuntimeConfig();
    if(role !== Role.evaluators){
      reject(new Error("is not evaluators"));
    }
    const { data, pending, error, refresh } = await useFetch("/battle/select",
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + battle.auth.token
      }, 
      body: JSON.stringify({
        select_id: select_user_id,
        battle_id: battle.battle_info.battle_chan}),
      baseURL: `${config.public.apiBaseUrl}`,
      onResponse({ request, response, options }) {
        if (response && response._data) {
          // console.log(response._data);
          resolve(response._data);
        } else {
          reject(new Error("Response data not found"));
        }
      }
    });
    if (error) {
      reject(error);
    }
  });
 
  
}

export async function useLoadProfile(user_id) {
  return new Promise(async (resolve, reject) => {
    const battle = useBattleStore();
    const config = useRuntimeConfig();
    const { data, pending, error, refresh } = await useFetch("/battle/profile/"+user_id,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'authorization': 'Bearer ' + battle.auth.token
        }, 
        baseURL: `${config.public.apiBaseUrl}`,
        onResponse({ request, response, options }) {
          if (response && response._data) {
            // console.log(response._data);
            resolve(response._data);
          } else {
            reject(new Error("Response data not found"));
          }
        }
      });
      if (error) {
        reject(error);
      }
    });
}

export async function useUpdateMe(user,icon) {
  const battle = useBattleStore();
  const config = useRuntimeConfig();
  let formData = new FormData();
  formData.append('name', user.name);
  formData.append('desc', user.desc);
  if(icon){
    formData.append('icon', icon, 'icon.jpg');
  }
  formData.append('selected', user.selected?user.selected.join(','):'');

  const { data, pending, error, refresh } = await useFetch("/battle/user",
  {
    method: 'POST',
    headers: {
      'authorization': 'Bearer ' + battle.auth.token
    },
    body: formData,
    baseURL: `${config.public.apiBaseUrl}`,
    onRequestError({ request, options, error }) {
    },
    onResponse({ request, response, options }) {
      battle.auth.user.name = response._data.name;
      battle.auth.user.selected = response._data.selected?response._data.selected.split(','):[];
      battle.auth.user.desc = response._data.desc;
      battle.auth.user.icon = response._data.icon;
      battle.auth.user.score = response._data.score;
      if(battle.battle_info.status>0){
        //更新cache
        battle.users_cache[battle.auth.user.id] = battle.auth.user; 
        //更新opponents
        for(let i=0;i<battle.battle_info.opponents.length;i++){
          if(battle.battle_info.opponents[i].id == battle.auth.user.id){
            battle.battle_info.opponents[i] = battle.auth.user;
          }
        }
        //更新evaluators
        for(let i=0;i<battle.battle_info.evaluators ;i++){
          if(battle.battle_info.opponents[i].id == battle.auth.user.id){
            battle.battle_info.opponents[i] = battle.auth.user;
          }
        }
      }
      
    },
    onResponseError({ request, response, options }) {
    }
  }
  );
  
}

export async function useGetUser(uuid) {
  return new Promise(async (resolve, reject) => {
    const battle = useBattleStore();
    const config = useRuntimeConfig();
    const { data, pending, error, refresh } = await useFetch("/battle/user/" + uuid, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + battle.auth.token
      },
      baseURL: `${config.public.apiBaseUrl}`,
      onResponse({ request, response, options }) {
        if (response && response._data) {
          // console.log(response._data);
          resolve(response._data);
        } else {
          reject(new Error("Response data not found"));
        }
      }
    });

    if (error) {
      reject(error);
    }
  });
}


export async function useLogin(username, password) {
  const config = useRuntimeConfig()
  const { data, pending, error, refresh } = await useFetch("/login",
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({account: username, password: password}),
    baseURL: `${config.public.apiBaseUrl}`,
  });
  console.log(data);
 return {
  data:data.value,
  error
};
}

export async function useLoadRanking() {
  return new Promise(async (resolve, reject) => {
    const battle = useBattleStore();
    const config = useRuntimeConfig();
    const { data, pending, error, refresh } = await useFetch("/battle/ranking",
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'authorization': 'Bearer ' + battle.auth.token
        }, 
        baseURL: `${config.public.apiBaseUrl}`,
        onResponse({ request, response, options }) {
          if (response && response._data) {
            // console.log(response._data);
            resolve(response._data);
          } else {
            reject(new Error("Response data not found"));
          }
        }
      });
      if (error) {
        reject(error);
      }
    });
}


export async function useRecovery(battle,callback) {
  // console.log('useGameEnter');
  // const battle = useBattleStore();
  const config = useRuntimeConfig();
  const { data, pending, error, refresh } = await useFetch("/battle/status",
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + battle.auth.token
    }, 
    baseURL: `${config.public.apiBaseUrl}`,
    onResponse({ request, response, options }) {
      // console.log(response._data);
      callback(true,response._data)
    },
    onResponseError({ request, response, options }) {
      if(response.status == 401){
        battle.auth.isAuthenticated = false;
      }
      callback(true,undefined)
    },
    onRequestError({ request, options, error }) {
      callback(true,undefined)
    }
  }
  );
}


export async function useRegister(account,password,icon,nickname) {
  return new Promise(async (resolve, reject) => {
    const config = useRuntimeConfig();
    let formData = new FormData();
    formData.append('account', account);
    formData.append('password', password);
    if(icon){
      formData.append('icon', icon, 'icon.jpg');
    }
    formData.append('name', nickname);
    const { data, pending, error, refresh } = await useFetch("/register",
      {
        method: 'POST',
        body: formData,
        baseURL: `${config.public.apiBaseUrl}`,
        onResponse({ request, response, options }) {
          if (response && response._data) {
            // console.log(response._data);
            resolve(response._data);
          } else {
            reject(new Error("Response data not found"));
          }
        }
      });
      if (error) {
        reject(error);
      }
    });
}

export async function useGetReread(battle) {
  return new Promise(async (resolve, reject) => {
    const config = useRuntimeConfig();
    // const battle = useBattleStore();
    const { data, pending, error, refresh } = await useFetch("/battle/reread",
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'authorization': 'Bearer ' + battle.auth.token
        }, 
        baseURL: `${config.public.apiBaseUrl}`,
        onResponse({ request, response, options }) {
          if (response && response._data) {
            resolve(response._data);
          } else {
            reject(new Error("Response data not found"));
          }
        }
      });
      if (error) {
        reject(error);
      }
    });
}

export async function usePostReread(battle,sendBokeinfo) {
  return new Promise(async (resolve, reject) => {
    const config = useRuntimeConfig();
    // const battle = useBattleStore();
    const { data, pending, error, refresh } = await useFetch("/battle/reread",
      {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
          'authorization': 'Bearer ' + battle.auth.token
        },
        body: JSON.stringify({
          battle_id: sendBokeinfo.battle_id, 
          select_id: sendBokeinfo.select_id
        }),
        baseURL: `${config.public.apiBaseUrl}`,
        onResponse({ request, response, options }) {
          if (response && response._data) {
            resolve(response._data);
          } else {
            reject(new Error("Response data not found"));
          }
        }
      });
      if (error) {
        reject(error);
      }
    });
}

export async function useReBattle(battle,battle_id) {
  return new Promise(async (resolve, reject) => {
    // const battle = useBattleStore();
    const config = useRuntimeConfig();
    const { data, pending, error, refresh } = await useFetch("/battle/rebattle",
      {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
          'authorization': 'Bearer ' + battle.auth.token
        },
        body: JSON.stringify({
          battle_id: battle_id
        }),
        baseURL: `${config.public.apiBaseUrl}`,
        onResponse({ request, response, options }) {
          if (response && response._data) {
            resolve(response._data);
          } else {
            reject(new Error("Response data not found"));
          }
        }
      });
      if (error) {
        reject(error);
      }
    });
}


export async function useCancelReBattle(battle,battle_id) {
  return new Promise(async (resolve, reject) => {
    // const battle = useBattleStore();
    const config = useRuntimeConfig();
    const { data, pending, error, refresh } = await useFetch("/battle/rebattle",
      {
        method: 'DELETE', 
        headers: {
          'Content-Type': 'application/json',
          'authorization': 'Bearer ' + battle.auth.token
        },
        body: JSON.stringify({
          battle_id: battle_id
        }),
        baseURL: `${config.public.apiBaseUrl}`,
        onResponse({ request, response, options }) {
          if (response && response._data) {
            resolve(response._data);
          } else {
            reject(new Error("Response data not found"));
          }
        }
      });
      if (error) {
        reject(error);
      }
    });
}

export async function useShuffleBattle(battle,room_id) {
  return new Promise(async (resolve, reject) => {
    const config = useRuntimeConfig();
    const { data, pending, error, refresh } = await useFetch("/battle/shuffle",
      {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
          'authorization': 'Bearer ' + battle.auth.token
        },
        body: JSON.stringify({
          battle_id: room_id,
        }),
        baseURL: `${config.public.apiBaseUrl}`,
        onResponse({ request, response, options }) {
          if (response && response._data) {
            resolve(response._data);
          } else {
            reject(new Error("Response data not found"));
          }
        }
      });
      if (error) {
        reject(error);
      }
    });
}

export async function useEnterRoom(room_id) {
  console.log("useEnterRoom");
  return new Promise(async (resolve, reject) => {
    const battle = useRoomStore();
    const config = useRuntimeConfig();
    const { data, pending, error, refresh } = await useFetch("/room",
      {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(room_id?{
          room_id: room_id,
          uuid: battle.getUUID(),
          icon_id: battle.getIconID(),
          nickname: battle.getNickname(),
          enter: true,
        }:{
          uuid: battle.getUUID(),
          icon_id: battle.getIconID(),
          nickname: battle.getNickname(),
          enter: true,
        }),
        baseURL: `${config.public.apiBaseUrl}`,
        onResponse({ request, response, options }) {
          if (response && response._data) {
            resolve(response._data);
          } else {
            reject(new Error("Response data not found"));
          }
        }
      });
      if (error) {
        reject(error);
      }
    });
}

export async function useExitRoom() {
  return new Promise(async (resolve, reject) => {
    const battle = useRoomStore();
    const config = useRuntimeConfig();
    const { data, pending, error, refresh } = await useFetch("/room",
      {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
          'authorization': 'Bearer ' + battle.auth.token
        },
        body: JSON.stringify({
          room_id: battle.room,
          uuid: battle.getUUID(),
          enter: false,
        }),
        baseURL: `${config.public.apiBaseUrl}`,
        onResponse({ request, response, options }) {
          if (response && response._data) {
            resolve(response._data);
          } else {
            reject(new Error("Response data not found"));
          }
        }
      });
      if (error) {
        reject(error);
      }
    });
}

export async function useUpdateRoomUser(nickname,icon_id) {
  return new Promise(async (resolve, reject) => {
    const battle = useRoomStore();
    const config = useRuntimeConfig();
    const { data, pending, error, refresh } = await useFetch("/battle/room/profile",
      {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
          'authorization': 'Bearer ' + battle.auth.token
        },
        body: JSON.stringify({
          uuid: battle.getUUID(),
          nickname:nickname,
          icon_id:icon_id
        }),
        baseURL: `${config.public.apiBaseUrl}`,
        onResponse({ request, response, options }) {
          if (response && response._data) {
            resolve(response._data);
          } else {
            reject(new Error("Response data not found"));
          }
        }
      });
      if (error) {
        reject(error);
      }
    });
}

export async function useAddInBattle(role) {
  return new Promise(async (resolve, reject) => {
    const battle = useRoomStore();
    const config = useRuntimeConfig();
    const { data, pending, error, refresh } = await useFetch("/battle/room/user",
      {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
          'authorization': 'Bearer ' + battle.auth.token
        },
        body: JSON.stringify({
          role: role,
        }),
        baseURL: `${config.public.apiBaseUrl}`,
        onResponse({ request, response, options }) {
          if (response && response._data) {
            resolve(response._data);
          } else {
            reject(new Error("Response data not found"));
          }
        }
      });
      if (error) {
        reject(error);
      }
    });
}

export async function useCancelBattle(uuid) {
  return new Promise(async (resolve, reject) => {
    const battle = useRoomStore();
    const config = useRuntimeConfig();
    const { data, pending, error, refresh } = await useFetch("/battle/room/user",
      {
        method: 'DELETE', 
        headers: {
          'Content-Type': 'application/json',
          'authorization': 'Bearer ' + battle.auth.token
        },
        body: JSON.stringify({
          uuid: uuid,
        }),
        baseURL: `${config.public.apiBaseUrl}`,
        onResponse({ request, response, options }) {
          if (response && response._data) {
            resolve(response._data);
          } else {
            reject(new Error("Response data not found"));
          }
        }
      });
      if (error) {
        reject(error);
      }
    });
}


export async function useRoomUserKick(battle,select_user_id) {
  // const battle = useBattleStore();
  return new Promise(async (resolve, reject) => {
    const config = useRuntimeConfig();
    const { data, pending, error, refresh } = await useFetch("/battle/room/userkick",
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + battle.auth.token
      }, 
      body: JSON.stringify({
        uuid: select_user_id
      }),
      baseURL: `${config.public.apiBaseUrl}`,
      onResponse({ request, response, options }) {
        if (response && response._data) {
          resolve(response._data);
        } else {
          reject(new Error("Response data not found"));
        }
      }
    });
    if (error) {
      reject(error);
    }
  });
}

export async function useBattleReport(battle,status) {
  return new Promise(async (resolve, reject) => {
    const config = useRuntimeConfig();
    const { data, pending, error, refresh } = await useFetch("/battle/report",
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + battle.auth.token
      }, 
      body: JSON.stringify({
        batte_id: battle.battle_info.battle_chan,
        status: status==='up'?1:0,
      }),
      baseURL: `${config.public.apiBaseUrl}`,
    });
  });
}