import { defineStore } from 'pinia'

export const useCommonStore = defineStore(
'bokete',{
  state: () => ({
    ani: true,
    }),
  getters: { 
  },
  actions: {
  },
  persist: {
    storage: persistedState.localStorage,
    enabled: true
  },
})
