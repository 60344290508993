export const TimeForBokete ={
    TimeForStop:0,
    TimeForReady:20,
    TimeForBattle:99,
    TimeForEvaluate:60,
    TimeForReBattle:10,
}

export const Role = {
    // unkonwn : 不明
    unkonwn: 0,
    // opponents : 試合参加者
    opponents: 1,
    // evaluators : 評価者
    evaluators: 2,
    // evaluators : 観戦者
    spectators: 3,
}

export const BattleStatus = {
    // unkonwn : 不明
    unkonwn: 0,
    // matching : マッチング中
    matching: 1,
    readying: 2,
    //対戦Start
    // 出題中
    odaiing: 3,
    // battling : 試合中
    battling: 4,
    // evaluating: 评价中
    evaluating: 5,
    //対戦End
    // finished : 終了
    finished: 6,
    // forcestop : ユーザーにより強制終了
    userstop: 998,
    // forcestop : システムにより強制終了
    forcestop: 999,
    
}

export const menulist =[
    { title: '退出する', index: 0 },
    { title: '使い方', index:1 },
    { title: 'バトルを強制終了', index:2 },
    { title: '問い合わせ', index:3 },]

export class User {
    constructor() {
        this.id = undefined;
        this.name = undefined;
        this.desc = undefined;
        this.icon = undefined;
        this.starCount = 0;
        this.selected = [];
        this.score = 0;
        this.role = Role.unkonwn;
    }

    fillUserData(callback) {
        const this_ = this;
        useGetUser(this.id).then((user) => {
            this_.id = user.uuid;
            this_.name = user.name;
            this_.desc = user.desc;
            this_.icon = `${user.icon}`;
            this_.selected = user.selected?user.selected.split(','):[];
            this_.score = user.score;
            if (callback && typeof callback === 'function') {
                callback(this_);
            }
        }).catch(err => {
            console.error("Error:", err.message);
        })
    }


    //認証から取得
    static getFromAuth(data) {
        let user = {
            id: data.uuid,
            name:data.name, 
            desc: data.desc,
            icon: `${data.icon}`,
            role: Role.unkonwn,
            score: data.score,
            selected: data.selected.split(',')};
        return user;
    }
}

// desc: odai model.
export class Odai {
    constructor() {
        this.id = 0;
        this.owner = '';
        this.url = '';
    }
}

export class Boke {
    constructor() {
        this.id = 0;
        this.odai = undefined;
        this.content = undefined;
        this.user_id = '0';
        this.created_at = new Date();
        this.updated_at = this.created_at;
    }

}


export class Battle {
    constructor() {
        this.id = 0;
        this.odai = undefined;

        this.opponents = [];
        this.evaluators = [];
        this.ready = [];
        this.result = {};
        this.result_private = {};

        this.status = BattleStatus.unkonwn;
        this.key = "";
        this.match_chan = "";
        this.battle_chan = "";
        this.victory = "";
        this.decrypted = false;
        this.created_at = new Date();
    }

}


export class Auth {
    constructor() {
        //全部のAPIに共通する認証情報
        this.token = undefined;
        this.isAuthenticated = false;
        this.user = undefined;
    }
}

export const characters = {
    1:"えいじぃ",
    2:"ゆきちゃ",
    3:"おさむん",
    4:"ありさ",
    5:"だんご",
    6:"とまるん",
    7:"かわさん",
    8:"かずさん",
    9:"けんけん",
    10:"きぃ",
    11:"りょうち",
    12:"いたるこ",
    13:"かとちゃ",
    14:"みおっち",
    15:"タイヤマン",
    16:"ボケイヌ",
    17:"あかさん",
    18:"ねこさん",
    19:"いぬさん",
}


export const playerMenulist =[
        { title: 'kickする' },
        ]