import { collection, doc, getDoc, onSnapshot, getFirestore, query } from "firebase/firestore"; 
export function useGameStart(ch,callback) {
  const firestore = getFirestore();
  // console.log("useGameStart=>",ch);
  const unsub = onSnapshot(doc(firestore, "battle_level_1", ch), (doc) => {
    if (typeof callback === 'function') {
      // console.log("useGameStart=>",doc.data());
      callback(doc.data());
    }
  });
  return unsub;
}

export function usePreBattle(ch,callback) {
  const firestore = getFirestore();
  const unsub = onSnapshot(doc(firestore, "tmp", ch), (doc) => {
    if (typeof callback === 'function') {
      // console.log("usePreBattle=>",doc.data());
      callback(doc.data());
    }else{
      //  console.log("No callback function");
    }
  });
  return unsub;
}

export function useGetBattleID(room,callback) {
  const firestore = getFirestore();
  const docRef = doc(firestore, "room", room);
  getDoc(docRef).then((doc) => {
    if (typeof callback === 'function') {
      callback(doc.data());
    }
  });
}

// /room/161b2acc24399e55076bc02ecf3af60f/users
export function useSnapListUser(room,callback) {
  const firestore = getFirestore();
  const searchQuery = query(collection(firestore, "room",room,"users"));
  const unsub = onSnapshot(searchQuery, (querySnapshot) => {
    if (typeof callback === 'function') {
      const users = [];
      querySnapshot.forEach((doc) => {
          users.push(doc.data());
      });
      callback(users);
    }
  });
  return unsub;
}

